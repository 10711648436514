import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { connect } from "react-redux";
import SEO from "../components/seo";
import Select from "react-select";
import { authPerfilSaveInit } from "../redux/auth/actions";
import { appRedirect } from "../redux/app/actions";
import { useIntl, navigate } from "gatsby-plugin-intl";
import Tools from "../classes/tools";

const EditarCuenta = ({ usuario, boot, authResolved, loggedIn, authPerfilSaveInit, appRedirect, s }) => {
	const intl = useIntl();

	const [email, setEmail] = useState("");
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");
	const [descripcion, setDescripcion] = useState("");
	const [descripcionCaracteres, setDescripcionCaracteres] = useState(0);
	const [pais, setPais] = useState({});
	const [paises, setPaises] = useState([]);
	const [zona, setZona] = useState({});
	const [zonas, setZonas] = useState([]);
	const [localizacion, setLocalizacion] = useState({});
	const [localizaciones, setLocalizaciones] = useState([]);
	const [dni, setDNI] = useState("");
	const [telefono, setTelefono] = useState("");

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [loggedIn, authResolved]);

	useEffect(() => {
		if (boot.loaded && authResolved) {
			// mostrar datos del usuario

			setEmail(usuario.email);
			setNombre(usuario.nombre);
			setApellido(usuario.apellido);
			setDescripcion(usuario.descripcion);
			setDescripcionCaracteres(usuario.descripcion.length);

			if (usuario.pais) {
				setPais({
					value: usuario.pais.id,
					label: usuario.pais.title,
				});
			}

			if (usuario.zona) {
				setZona({
					value: usuario.zona.id,
					label: usuario.zona.title,
				});
			}

			if (usuario.localizacion) {
				setLocalizacion({
					value: usuario.localizacion.id,
					label: usuario.localizacion.title,
				});
			}

			setDNI(usuario.dni);
			setTelefono(usuario.telefono);

			// poblar selects por primera vez

			const ps = [];
			const zs = [];
			const ls = [];
			boot.paises.forEach((p) => {
				ps.push({
					value: p.id,
					label: p.title,
				});
				if (usuario.pais) {
					if (p.id === usuario.pais.id) {
						p.zonas.forEach((z) => {
							zs.push({
								value: z.id,
								label: z.title,
							});
							if (usuario.zona) {
								if (z.id === usuario.zona.id) {
									z.localizaciones.forEach((l) => {
										ls.push({
											value: l.id,
											label: l.title,
										});
									});
								}
							}
						});
					}
				}
			});
			setPaises(ps);
			setZonas(zs);
			setLocalizaciones(ls);
		}
	}, [boot.loaded, authResolved]);

	useEffect(() => {
		setDescripcionCaracteres(descripcion.length);
		if (descripcion.length > 200) {
			setDescripcion(descripcion.substr(0, 200));
		}
	}, [descripcion]);

	const handlePaisChange = (item) => {
		const zs = [];
		boot.paises.forEach((p) => {
			if (p.id === item.value) {
				setPais({
					value: p.id,
					label: p.title,
				});
				p.zonas.forEach((z) => {
					zs.push({
						value: z.id,
						label: z.title,
					});
				});
			}
		});
		setZona({});
		setZonas(zs);
		setLocalizacion({});
		setLocalizaciones([]);
	};

	const handleZonaChange = (item) => {
		const ls = [];
		boot.paises.forEach((p) => {
			p.zonas.forEach((z) => {
				if (z.id === item.value) {
					setZona({
						value: z.id,
						label: z.title,
					});
					z.localizaciones.forEach((l) => {
						ls.push({
							value: l.id,
							label: l.title,
						});
					});
				}
			});
		});
		setLocalizacion({});
		setLocalizaciones(ls);
	};

	const handleLocalizacionChange = (item) => {
		setLocalizacion(item);
	};

	const [imagenSrc, setImagenSrc] = useState(null);
	var imagenReader = null;
	try {
		imagenReader = new FileReader(); // sino, webpack o quien sea no encuentra la clase
		imagenReader.addEventListener(
			"load",
			() => {
				const img = new Image();
				img.onload = () => {
					let w = 400;
					let h = 400;
					if (img.width > img.height) {
						h = w / (img.width / img.height);
					} else {
						w = h / (img.height / img.width);
					}
					const canvas = document.createElement("canvas");
					canvas.width = w;
					canvas.height = h;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, w, h);
					setImagenSrc(canvas.toDataURL("image/jpeg"));
				};
				img.src = imagenReader.result;
			},
			false
		);
	} catch {}

	const handleImagenChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const allowedTypes = ["image/jpeg", "image/png"];
			if (allowedTypes.indexOf(file.type) > -1) {
				imagenReader.readAsDataURL(e.target.files[0]);
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		authPerfilSaveInit(intl.locale, {
			token: usuario.token,
			email,
			nombre,
			apellido,
			descripcion,
			pais: pais.value,
			zona: zona.value,
			localizacion: localizacion.value,
			dni,
			telefono,
			imagen: imagenSrc,
		});
	};

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="profile-edit">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.editarcuenta?.titulo)} />
					{authResolved && loggedIn && (
						<main className="page form-page lightgray-bg-01">
							{/* EDIT */}
							<div className="steps-wrapper">
								<article className="form-box box">
									<div className="wrapper">
										{!usuario.completo && (
											<div
												style={{
													padding: "15px 20px",
													borderRadius: 5,
													border: "2px solid #bb0000",
													marginBottom: 40,
												}}
											>
												{Tools.convertString(s.editarcuenta?.incompleto)}
											</div>
										)}
										<form onSubmit={handleSubmit}>
											<fieldset>
												<h4 className="black-text-03 prequel-font">{Tools.convertString(s.editarcuenta?.titulo)}</h4>
												<label className="black-text-01 file-upload">
													{usuario.imagen && imagenSrc === null && (
														<img
															className="profile-img"
															src={usuario.imagen.url}
															alt={Tools.convertString(s.editarcuenta?.foto_placeholder)}
														/>
													)}
													{imagenSrc !== null && (
														<img
															className="profile-img"
															src={imagenSrc}
															alt={Tools.convertString(s.editarcuenta?.foto_placeholder)}
														/>
													)}
													{imagenSrc === null && !usuario.imagen && (
														<img
															className="profile-img"
															src="/img/upload-icon.svg"
															alt={Tools.convertString(s.editarcuenta?.foto_placeholder)}
														/>
													)}
													<small className="black-text-03">
														{Tools.convertString(s.editarcuenta?.foto_aclaracion)}
														<span>
															<input
																type="file"
																className="file"
																size="29"
																length="4096"
																onChange={handleImagenChange}
															/>
															{Tools.convertString(s.editarcuenta?.cambiar)}
															<input className="upload lightgray-bg-01" />
														</span>
													</small>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.email)}
													<input
														className="input lightgray-bg-01 black-text-03"
														type="text"
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.nombre)}
													<input
														className="input lightgray-bg-01 black-text-03"
														type="text"
														value={nombre}
														onChange={(e) => setNombre(e.target.value)}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.apellido)}
													<input
														className="input lightgray-bg-01 black-text-03"
														type="text"
														value={apellido}
														onChange={(e) => setApellido(e.target.value)}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.descripcion)}
													<textarea
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.editarcuenta?.descripcion_placeholder)}
														onChange={(e) => setDescripcion(e.target.value)}
														value={descripcion}
													></textarea>
													<div className="align-right">
														<small className="lightgray-text-05 align-right">
															Caracteres restantes: <b>{200 - descripcionCaracteres}</b>
														</small>
													</div>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.pais)}
													<Select
														classNamePrefix="Select"
														options={paises}
														onChange={handlePaisChange}
														value={pais}
														components={{ IndicatorSeparator: () => null }}
													></Select>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.zona)}
													<Select
														classNamePrefix="Select"
														options={zonas}
														onChange={handleZonaChange}
														value={zona}
														components={{ IndicatorSeparator: () => null }}
													></Select>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.localizacion)}
													<Select
														classNamePrefix="Select"
														options={localizaciones}
														onChange={handleLocalizacionChange}
														value={localizacion}
														components={{ IndicatorSeparator: () => null }}
													></Select>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.dni)}
													<input
														className="input lightgray-bg-01 black-text-03"
														type="text"
														value={dni}
														placeholder={Tools.convertString(s.editarcuenta?.dni_placeholder)}
														onChange={(e) => setDNI(e.target.value)}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.editarcuenta?.telefono)}
													<input
														className="input lightgray-bg-01 black-text-03"
														type="text"
														value={telefono}
														placeholder={Tools.convertString(s.editarcuenta?.telefono_placeholder)}
														onChange={(e) => setTelefono(e.target.value)}
													/>
												</label>
											</fieldset>

											<div className="button-wrapper lightgray-bg-01">
												<button type="submit" className="button black-bg-03 white-text">
													{Tools.convertString(s.editarcuenta?.guardar)}
												</button>
											</div>
										</form>
									</div>
								</article>
							</div>
							{/* /EDIT */}
							<Footer />
						</main>
					)}
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { boot } = state.app;
	const { usuario, resolved: authResolved, loggedIn } = state.auth;
	return {
		usuario,
		boot,
		authResolved,
		loggedIn,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	authPerfilSaveInit,
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCuenta);
